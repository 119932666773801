import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import gamer from "../../Assets/Projects/gamer.jpeg"; //
import fortheye from "../../Assets/Projects/fortheye.svg"; //
import obj from "../../Assets/Projects/obj.jpg"; //
import uzox from "../../Assets/Projects/uzox.png"; //
import credge from "../../Assets/Projects/credge.jpeg"; //
import food from "../../Assets/Projects/food.png";
import connect from "../../Assets/Projects/connect.png"; //

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Experiences</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few major projects and companies I've worked with recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={uzox}
              isBlog={false}
              title="Uzox"
              description="The ultimate AI based music app on Discord with over 25 million users in over 350k communities and 120 countries. Uses 20+ servers worldwide to host all its microservices. Play audio from sources like SoundCloud, Deezer and Twitch with external link support through YouTube, Spotify and Apple Music."
              ghLink="https://github.com/uzox/uzox/"
              demoLink="https://uzox.xyz/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={food}
              isBlog={false}
              title="Food Donor"
              description="The first ever app that connects restaurants with excess food to volunteers who are willing to distribute it to the homeless. A completely self sustained initiative with minimal maintenance. Private portal for restaurants and volunteers under construction."
              ghLink="https://github.com/orgs/FoodDonor/repositories"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={obj}
              isBlog={false}
              title="Object Detection AI"
              description="AI object-detection model for autonomous cars to use cameras instead of LiDAR. We used the CIFAR10 dataset along with CNNs to train the model. Incorporated the YOLO model along with darknet to pre-process the data improving efficiency. Resulted in a final model that was efficient in live detection of objects through a web-cam."
              ghLink="https://github.com/Moquent/ObjectDetectionAI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gamer}
              isBlog={true}
              title="Gamer.XYZ"
              description="Gamify community involvement through customizable loyalty programs for authentic engagement. Developed a website-to-Discord integration that helped the community of 20k+ active members. Organized community events to foster engagement. Helped build a prize distribution system to grow the userbase."
              ghLink="https://gamer.xyz/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={credge}
              isBlog={true}
              title="Credge Education"
              description="Global learning platform which offers future-focused courses for all ages featuring world-class mentors, interactive and holistic curriculum infused with cutting-edge technologies. Expanded the user base to Discord and created an AI model for administration of the community. Revamped one of the user submission processes to optimize time and efficiency and reduce human error. Worked on an on-site internship at an event to market a product to thousands of potential users."
              ghLink="https://credge.education/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fortheye}
              isBlog={true}
              title="Fortheye"
              description="One of the country’s leading mobile app, product, and web development companies. Worked on internal API development. Created routes and implemented database calls with millisecond efficiency. Built a Discord community from scratch to help grow the userbase. Designed and automated an alert system for the community to help users find placements and improve skills with courses."
              ghLink="https://fortheye.co/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={connect}
              isBlog={true}
              title="ConnectMyWorld"
              description="Solutions for field staff monitoring, task management and much more, providing private services for client companies and helps remotely manage the team environment. Researched with the battery optimisation team to increase efficiency of the application. Researched to improve and find better adaptability options for UI/UX in the application with thorough back-testing. Collaborated with the founder and team to help build the website and host them over cloud virtualmachines."
              ghLink="https://www.connectmyworld.in/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
